#introduction {
  padding: 5vh 5%;
  margin: 0 -5px;
  background-color: #a2c8ec;
}

#introduction img {
  height: 50vh;
  width: 100%;
  border-radius: 3px;
  border: 3px solid #fff;
}

#introduction .title {
  font-size: 36px;
  text-align: unset;
  max-width: 500px;
  margin-top: 10vh;
}
.intContent2 {
  width: 60%;
  padding: 10vh 0;
}

@media (max-width: 600px) {
  #introduction {
    padding: 5vh 0;
    margin: 0px;
    width: 100%;
    text-align: center;
  }
  #introduction img {
    height: 40vh;
    display: inline;
    width: 90%;
  }

  #introduction .title {
    margin-top: 10px;
  }

  .imgIntroductionTitle {
    font-size: 32px;
    width: unset;
  }

  .imgIntroduction .button {
    display: inline;
  }
}
