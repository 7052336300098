.contestants-root .title {
  padding-bottom: 3vh;
}

.contestants {
  /* padding: 5vh 2%; */
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
}

.contestant-container {
  padding: 5vh 2%;
}

.contestant {
  padding: 5vh 2%;
}

@media (max-width: 1200px) {
  .contestants {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .contestants {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .contestants {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .contestants {
    grid-template-columns: repeat(1, 1fr);
  }
}
