* {
  font-family: "Roboto", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.root {
  padding: 0;
  background-color: #f8f8f8;
}
.navbar {
  top: 0px;
  width: 100%;
}

.footer {
  position: relative;
  top: 2vh;
  bottom: 0;
  width: 100%;
  display: inline-block;
}
