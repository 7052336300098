.navRoot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.navContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: grey;
}

.navLinks {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 10px;
}
.active_content {
  padding: 10px;
  border-bottom: 1px solid #000;
  cursor: pointer;
}

.content:hover {
  cursor: pointer;
  border-bottom: 1px solid #000;
}

.navLogo {
  padding: 10px;
  cursor: pointer;
}

.navButton {
  cursor: pointer;
}

.button {
  background-color: #e19327;
  color: white;
  border: 1px solid #e19327;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.button:hover {
  background-color: #fff;
  color: #e19327;
  border: 1px solid #e19327;
}

#publishers {
  background-color: #ff9800;
  color: white;
  border: 1px solid #ff9800;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
}

#publishers:hover {
  background-color: #fff;
  color: #ff9800;
  border: 1px solid #ff9800;
}

.hide {
  display: none;
}

.navLinksActive {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  margin-left: 100%;
  height: 35vh;
  background-color: #fff;
  z-index: 3;
  width: 110%;
  padding: 10px 100px;
  float: right;
}

.showButton {
  background-color: #e19327;
  color: white;
  border: 1px solid #e19327;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.showButton:hover {
  background-color: #fff;
  color: #e19327;
  border: 1px solid #e19327;
}

@media (max-width: 600px) {
  .navLinks {
    display: none;
  }
  .button {
    display: none;
  }

  #show_in_mobile {
    display: inline;
  }

  .content:hover {
    border-bottom: none;
  }
}
